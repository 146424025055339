class Hours {
    constructor (monday, tuesday, wednesday, thursday, friday, saturday, sunday, lastOrders) {
        this.monday = monday;
        this.tuesday = tuesday;
        this.wednesday = wednesday;
        this.thursday = thursday;
        this.friday = friday;
        this.saturday = saturday;
        this.sunday = sunday;
        this.lastOrders = lastOrders;
    }
}
export default Hours

const hourConverter = {
    toFirestore: (hour) => {
        return {
            monday: hour.monday,
            tuesday: hour.tuesday,
            wednesday: hour.wednesday,
            thursday: hour.thursday,
            friday: hour.friday,
            saturday: hour.saturday,
            sunday: hour.sunday,
            lastOrders: hour.lastOrders
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Hours(data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday, data.sunday, data.lastOrders);
    }
}
export {Hours, hourConverter}