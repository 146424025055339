import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

import './admin.css'
import './adminMobile.css'
import SignIn from "./signIn/SignIn";
import {useNavigate} from "react-router-dom";



const Admin = (props) => {
  let navigate = useNavigate();

  // Firebase
  let currentUser = props.user;
  let setCurrentUser = props.setUser
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setCurrentUser(user);
    } else {
      // User is signed out
      setCurrentUser(null)
    }
  });

  return (
    <div className={'adminContainer'}>
      {currentUser === null ? <SignIn setUser={setCurrentUser} /> : <div>
        <button className={'adminBtn adminHomeBtn'} onClick={() => {
          navigate('/admin/beers')
        }}>BEERS
        </button>
        <br/>
        <button className={'adminBtn adminHomeBtn'} onClick={() => {
          navigate('/admin/events')
        }}>EVENTS
        </button>
        <br/>
        <button className={'adminBtn adminHomeBtn'} onClick={() => {
          navigate('/admin/hero')
        }}>HERO
        </button>
        <br/>
        <button className={'adminBtn adminHomeBtn'} onClick={() => {
          navigate('/admin/hours')
        }}>HOURS
        </button>
        <br/>
        <button className={'adminBtn adminHomeBtn'} onClick={() => {
          navigate('/admin/tables')
        }}>TABLES
        </button>
        <br/>
        <button className={'adminBtn adminHomeBtn adminLogoutBtn'} style={{fontSize: '2vh !important'}} onClick={() => {
          signOut(auth).then(() => {
            navigate('/admin')
          }).catch((error) => {
            // An error happened.
          });
        }}>LOGOUT
        </button>
      </div>}
    </div>
  )
}

export default Admin