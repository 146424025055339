class Quiz {
  constructor (date, soldOut, removed) {
    this.date = date;
    this.soldOut = soldOut;
    this.removed = removed;
  }
}

const quizConverter = {
  toFirestore: (quiz) => {
    return {
      date: quiz.date,
      soldOut: quiz.soldOut,
      removed: quiz.removed,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Quiz(data.date, data.soldOut, data.removed);
  }
}
export {Quiz, quizConverter}