import './craftBeer.css';
import './craftBeerMobile.css';
import './craftBeerTablet.css';

import {GatesheadCraftBeer, NewcastleCraftBeer} from "../../imgs";

const CraftBeer = () => {
    return (
        <div className={'craftBeerOuterContainer'}>
            <h1 style={{marginBottom: '20px'}}>CRAFT BEER TRAILS</h1>
            <p className={'craftBeerText'}><i>Click on the images below to get directions!</i></p>
            <div className={'craftBeerContainer'}>
                <img onClick={() => {
                    window.open('https://maps.app.goo.gl/rFwoxmc6V1M1qDSe9', '_blank');
                }} src={NewcastleCraftBeer} alt={'Map for Newcastle craft beer trail'}/>
                <img onClick={() => {
                    window.open('https://maps.app.goo.gl/eDeWuLVqXEhofKF26', '_blank');
                }} src={GatesheadCraftBeer} alt={'Map for Gateshead craft beer trail'}/>
            </div>
        </div>
    )
}

export default CraftBeer;