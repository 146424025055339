import './liquid.css';
import './liquidMobile.css';
import './liquidTablet.css';

const Liquid = (props) => {
  let tapOn = props.tapOn;
  let beer = props.beer;
  let row = props.row;

  let edge = props.edge;
  let mobile = props.mobile;

  let pourClassName = '';
  if (edge) {
    pourClassName = `pourLiquid beerPourLiquidRow${row} beerPourLiquidEdge`
  } else if (mobile) {
    pourClassName = `pourLiquid beerPourLiquidRow${row}`
  } else {
    pourClassName = `beerPourLiquidRow${row}`
  }

  let removeClassName = '';
  if (edge) {
    removeClassName = `removeLiquid beerRemoveLiquidRow${row} beerRemoveLiquidEdge`
  } else if (mobile) {
    removeClassName = `removeLiquid beerRemoveLiquidRow${row}`
  } else {
    removeClassName = `beerRemoveLiquidRow${row}`
  }

  return (
    <div className={'grid-item tap-and-liquid'}>
      {tapOn ?
        <div className={pourClassName} style={{backgroundColor: beer.tapColour}}></div> :
        <div className={removeClassName} style={{backgroundColor: beer.tapColour}}></div>}
    </div>
  )
}

export default Liquid;