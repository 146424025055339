import './floorPlan.css'
import Table from "./table/Table";
import {useEffect, useState} from "react";

const FloorPlan = (props) => {
  let {tables, setTables} = props;
  const [tableSwap, setTableSwap] = useState([]);

  useEffect(() => {
    if (tables.length > 0) {
      tables = tables.sort((a, b) => {
        return a.id - b.id;
      })
    }
  }, [tables]);

  function getTable(tables, id) {
    return tables.find(table => table.id === id)
  }

  const findTwoTablesOfX = (tables, number) => {
    const availableTables = tables.filter(t => t.capacity === number && t.currentSize === 0);
    return availableTables.length >= 2 ? availableTables.slice(0, 2) : null;
  };

  function swapTeamsBetweenTables(swapTeams) {
    let tables = props.tables.map(l => Object.assign({}, l));

    const isTeamAndIdSwap = typeof swapTeams[0].team === 'object' && typeof swapTeams[1] === 'number';

    if (isTeamAndIdSwap) {
      const doSwap = (sourceTable, targetTable, teamToMove, teamSize) => {
        if ((targetTable.currentSize + teamToMove.size > targetTable.capacity) && (sourceTable.currentSize > targetTable.capacity)) {
          let tablesOfTwo = findTwoTablesOfX(tables, 2)
          if (teamToMove.size <= 4 && tablesOfTwo !== null && tablesOfTwo.length >= 2) {
            let teamSize = teamToMove.size;
            for (const subTable of tablesOfTwo) {
              teamSize = teamSize - 2 <= 0 ? teamToMove.size - teamSize : teamSize - 2
              subTable.currentSize += teamSize;
              subTable.teams.push(teamToMove);
            }
          } else {
            alert("Target table does not have enough capacity.");
            return;
          }
        } else {
          // Add to target table
          targetTable.teams.push(teamToMove);
          targetTable.currentSize += teamSize;
        }

        // Remove from source table
        let teamIndex = sourceTable.teams.findIndex(team => team.teamId === teamToMove.teamId);
        sourceTable.teams.splice(teamIndex, 1);
        sourceTable.currentSize -= teamSize;
      }

      const teamToMove = swapTeams[0].team;

      // Find the tables
      let sourceTable = tables.filter(table => table.teams.some(team => team.teamId === teamToMove.teamId));  // Find if team is on more than 1 table
      const targetTable = getTable(tables, swapTeams[1]);

      if (!sourceTable || !targetTable) {
        alert("Team or target table not found.");
        return;
      }
      if (sourceTable.length === 1) {
        sourceTable = sourceTable[0];
      }

      if (!Array.isArray(sourceTable)) {
        doSwap(sourceTable, targetTable, teamToMove, teamToMove.size)
      } else if (Array.isArray(sourceTable)) {
        let teamSize = sourceTable.map((table) => table.currentSize).reduce((a, b) => a + b, 0);
        if (teamSize > targetTable.capacity) {
          sourceTable = getTable(tables, swapTeams[0].table.id)
          doSwap(sourceTable, targetTable, teamToMove, sourceTable.currentSize)
        } else {
          sourceTable[0].teams = [];
          sourceTable[0].currentSize = 0;
          sourceTable[1].teams = [];
          sourceTable[1].currentSize = 0;

          // Add to target table
          targetTable.teams.push(teamToMove);
          targetTable.currentSize += teamToMove.size;
        }
      }
    } else {
      // Find the tables containing the teams to be swapped
      let sourceTable = tables.filter(table => table.teams.some(team => team.teamId === swapTeams[0].team.teamId));
      let targetTable = tables.filter(table => table.teams.some(team => team.teamId === swapTeams[1].team.teamId));

      if (!sourceTable || !targetTable) {
        alert("One or both teams not found in any table.");
        return;
      }
      if (sourceTable.length === 1) {
        sourceTable = sourceTable[0];
      }
      if (targetTable.length === 1) {
        targetTable = targetTable[0];
      }

      function doSwap(sourceTable, targetTable) {
        // Find the indices of the teams within their respective tables
        const team1Index = sourceTable.teams.findIndex(team => team.teamId === swapTeams[0].team.teamId);
        const team2Index = targetTable.teams.findIndex(team => team.teamId === swapTeams[1].team.teamId);

        // Perform the swap
        [sourceTable.teams[team1Index], targetTable.teams[team2Index]] = [targetTable.teams[team2Index], sourceTable.teams[team1Index]];

        // Update currentSize for both tables
        sourceTable.currentSize = sourceTable.teams.reduce((sum, team) => sum + team.size, 0);
        targetTable.currentSize = targetTable.teams.reduce((sum, team) => sum + team.size, 0);
      }

      if (!Array.isArray(sourceTable) && !Array.isArray(targetTable)) {
        doSwap(sourceTable, targetTable)
      } else {
        // TODO: Fix current size to be updated properly (e.g. team of 3 going to to tables of 2)
        if (Array.isArray(sourceTable) && !Array.isArray(targetTable)) {
          let team1 = sourceTable[0].teams;
          let team2 = targetTable.teams;
          sourceTable[0].teams = team2;
          sourceTable[1].teams = team2;
          targetTable.teams = team1
        } else if (!Array.isArray(sourceTable) && Array.isArray(targetTable)) {
          let team1 = sourceTable.teams;
          let team2 = targetTable[0].teams;
          sourceTable.teams = team2;
          targetTable[0].teams = team1;
          targetTable[1].teams = team1;
        } else if (Array.isArray(sourceTable) && Array.isArray(targetTable)) {
          let team1 = sourceTable[0].teams;
          let team2 = targetTable[0].teams;
          sourceTable[0].teams = team2;
          sourceTable[1].teams = team2;
          targetTable[0].teams = team1;
          targetTable[1].teams = team1;
        }
      }
    }

    setTables(tables);
  }

  useEffect(() => {
    if (tableSwap.length === 2) {
      swapTeamsBetweenTables(tableSwap)
      setTableSwap([])
    }
  }, [tableSwap])

  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.59 848.97">
      <g id="Layer_1-2">
        <rect className="cls-3" x="6.59" y="5.65" width="90.72" height="134.27" rx="10" ry="10"/>
        <Table table={getTable(tables, 1)} x={6.59} y={5.65} width={90.72} height={134.27}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="259.71" y="5.65" width="106.96" height="106.96" rx="10" ry="10"/>
        <Table table={getTable(tables, 2)} x={259.71} y={5.65} width={106.96} height={106.96}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="218.88" width="83.47" height="134.27" rx="10" ry="10"/>
        <Table table={getTable(tables, 3)} x={6.59} y={218.88} width={83.47} height={134.27}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="232.39" y="620.06" width="134.27" height="73.47" rx="10" ry="10"/>
        <Table table={getTable(tables, 4)} x={232.39} y={620.06} width={134.27} height={73.47}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="232.39" y="252.15" width="134.27" height="73.47" rx="10" ry="10"/>
        <Table table={getTable(tables, 5)} x={232.39} y={252.15} width={134.27} height={73.47}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="128.62" y="82.58" width="83.47" height="134.27" rx="10" ry="10"/>
        <Table table={getTable(tables, 6)} x={128.62} y={82.58} width={83.47} height={134.27}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="434.48" width="134.27" height="73.47" rx="10" ry="10"/>
        <Table table={getTable(tables, 7)} x={6.59} y={434.48} width={134.27} height={73.47}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="801.65" width="124.17" height="40.77" rx="10" ry="10"/>
        <Table table={getTable(tables, 9)} x={6.59} y={801.65} width={124.17} height={40.77}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="242.49" y="801.65" width="124.17" height="40.77" rx="10" ry="10"/>
        <Table table={getTable(tables, 8)} x={242.49} y={801.65} width={124.17} height={40.77}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="728.83" width="95.95" height="63.95" rx="10" ry="10"/>
        <Table table={getTable(tables, 13)} x={6.59} y={728.83} width={95.95} height={63.95}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="659.61" width="95.95" height="62.34" rx="10" ry="10"/>
        <Table table={getTable(tables, 12)} x={6.59} y={659.61} width={95.95} height={63.95}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="588.89" width="95.95" height="63.85" rx="10" ry="10"/>
        <Table table={getTable(tables, 11)} x={6.59} y={588.89} width={95.95} height={63.95}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        <rect className="cls-3" x="6.59" y="518.07" width="95.95" height="63.95" rx="10" ry="10"/>
        <Table table={getTable(tables, 10)} x={6.59} y={518.07} width={95.95} height={63.95}
               tableSwap={tableSwap} setTableSwap={setTableSwap}/>
        {tables.length > 13 ? <>
          <rect className="cls-3" x="232.39" y="152.15" width="134.27" height="73.47" rx="10" ry="10"/>
          <Table table={getTable(tables, 14)} x={232.39} y={152.15} width={134.27} height={73.47}
                                              tableSwap={tableSwap} setTableSwap={setTableSwap}/></>
          :
          <></>}

        <rect className="cls-2" x="1" y="1" width="370.59" height="846.97" rx="15.28" ry="15.28"/>
        <path className="cls-1" d="m186.05,847.76v-23.39s31.42-4.6,38.91,23.34"/>
        <path className="cls-1" d="m186.05,847.79v-23.42s-31.03-4.6-38.42,23.36"/>
        <rect className="cls-1" x="304.96" y="466.76" width="66.63" height="133.26"/>
        <polyline className="cls-1" points="371.59 583.36 321.62 583.36 321.62 484.7 304.96 484.7"/>
        <rect className="cls-1" x="304.96" y="344.86" width="66.63" height="121.6"/>
        <path className="cls-5" d="m304.1,457.23h12.01s2.16-12.29-11.98-12.29"/>
        <path className="cls-1" d="m304.1,457.23h12.01s2.16-12.29-11.98-12.29"/>
        <line className="cls-2" x1="1" y1="214.42" x2="61.92" y2="214.42"/>
        <rect className="cls-4" x="7.83" y="149.97" width="43.27" height="56.71" rx="8.58" ry="8.58"/>

        <rect className="cls-4" x="6.59" y="381.3" width="35.33" height="46.31" rx="8.58" ry="8.58"/>
        <rect className="cls-4" x="6.59" y="360.02" width="35.33" height="14.41" rx="7.21" ry="7.21"/>

      </g>
    </svg>
  )
}

export default FloorPlan;