import './signIn.css';
import './signInMobile.css';

import {useState} from "react";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";

const SignIn = (props) => {
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let setUser = props.setUser;

  const auth = getAuth();

  return (
    <div className={'adminSignInContainer'}>
      <div className={'signInFields'}>
        <p><b>Email:</b></p>
        <input className={'adminInput'} id={'email'} type="email" onChange={e => {
          setEmail(e.target.value);
        }} value={email}/>
        <p><b>Password:</b></p>
        <input className={'adminInput'} id={'password'} type="password" onChange={e => {
          setPassword(e.target.value);
        }} value={password}/>
        <button className={'adminBtn'} onClick={() => {
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              setUser(user);
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
            });
        }}>SIGN IN
        </button>
      </div>
    </div>
  )
}

export default SignIn;