class Poster {
  constructor (name, imageUrl, eventUrl, expiry) {
    this.name = name;
    this.imageUrl = imageUrl;
    this.eventUrl = eventUrl;
    this.expiry = expiry;
  }
}

const posterConverter = {
  toFirestore: (poster) => {
    return {
      name: poster.name,
      imageUrl: poster.imageUrl,
      eventUrl: poster.eventUrl,
      expiry: poster.expiry
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Poster(data.name, data.imageUrl, data.eventUrl, data.expiry);
  }
}
export {Poster, posterConverter}