import './visitUs.css';
import './visitUsMobile.css';
import './visitUsTablet.css';
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {hourConverter as hoursConverter, Hours} from "../../objects/Hours";
import {useEffect, useState} from "react";

const VisitUs = (props) => {
    let width = props.width;

    const [hours, setHours] = useState(new Hours('Closed', 'Closed', '3pm-11pm',
        '3pm-11pm', '3pm-11pm', '1pm-11pm', '3pm-10pm', '30 mins before close'))
    const [groupedHours, setGroupedHours] = useState([])

    const getCurrentHours = async () => {
        const docHours = await getDoc(doc(db, "openingHours", "hours"));
        setHours(hoursConverter.fromFirestore(docHours))
    }

    function findIdenticalValues(obj) {
        const entries = Object.entries(obj);
        const result = [];
        let currentGroup = [];
        let currentValue = null;

        for (const [key, value] of entries) {
            if (value === currentValue) {
                currentGroup.push(key);
            } else {
                if (currentGroup.length > 0) {
                    result.push([currentGroup, currentValue]);
                }
                currentGroup = [key];
                currentValue = value;
            }
        }

        // Add the last group if it exists
        if (currentGroup.length > 0) {
            result.push([currentGroup, currentValue]);
        }

        return result;
    }

    useEffect(() => {
        getCurrentHours()
    }, []);

    const getAbbreviation = (value) => {
        if (value === "monday") {
            return "Mon"
        } else if (value === "tuesday") {
            return "Tues"
        } else if (value === "wednesday") {
            return "Wed"
        } else if (value === "thursday") {
            return "Thurs"
        } else if (value === "friday") {
            return "Fri"
        } else if (value === "saturday") {
            return "Sat"
        } else if (value === "sunday") {
            return "Sun"
        } else if (value === "lastOrders") {
            return "Last Orders"
        }
    }

    useEffect(() => {
        let groups = findIdenticalValues(hours)
        let newGroups = []

        for (let i = 0; i < groups.length; i++) {
            let days = groups[i][0]
            let value = groups[i][1]

            if (days.length === 1) {
                newGroups.push([getAbbreviation(days[0]), value])
            } else if (days.length === 2) {
                newGroups.push([`${getAbbreviation(days[0])} & ${getAbbreviation(days[1])}`, value])
            } else {
                newGroups.push([`${getAbbreviation(days[0])} - ${getAbbreviation(days[days.length - 1])}`, value])
            }
        }

        setGroupedHours(newGroups)
    }, [hours]);

    return (
        <div className={'visitUsHeader'}>
            <h1>VISIT US</h1>
            <div>
                {width > 640 ?
                    <div className={'openingHoursContainer'}>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index < 3) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 3 && index < 6) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 6) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    :
                    <div className={'openingHoursContainer'}>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index < 2) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 2 && index < 4) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 4 && index < 6) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div>
                            {groupedHours.map((value, index) => {
                                if (index >= 6) {
                                    return (
                                        <div className={'hourText'} key={index}>
                                            <b>{value[0]}:</b> {value[1]}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default VisitUs;