import './header.css';
import './headerMobile.css';

import {HeaderImg, HeaderMobileImg, Logo} from "../../imgs";
import useWindowDimensions from "../../func/getWindowDimensions";
import {useEffect, useState} from "react";

const Header = (props) => {
    const [style, setStyle] = useState("")
    const [top, setTop] = useState("-75px")


    useEffect(() => {
        if (!props.isLandingVisible) {
            if (props.show) {
                setStyle("appear 0.5s ease-in-out")
                setTimeout(() => {
                    setTop("0px")
                }, 500)
            }

        } else if (!props.show) {
            setStyle("disappear 0.5s ease-in-out")
            setTimeout(() => {
                setTop("-75px")
            }, 500)
        }
    }, [props.show]);

    return (
        <div onClick={() => {
            props.landingRef.current.scrollIntoView({behavior: 'smooth'})
        }} style={{animation: style, top: top}} ref={props.refProp} className={'headerBar'}>
            <img src={Logo} />
            <div onClick={(e) => {e.stopPropagation();}}>
                <a href={"https://www.getintonewcastle.co.uk/things-to-do/five-minutes-with-mosaic-tap-owner-isaac-vernon"} target={"_blank"} rel={"noopener"}>Click to read our interview with <b>'Get Into Newcastle'</b> here!</a>
            </div>
            <div></div>
        </div>
    )
}

export default Header