// React
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";

// Firebase
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {addDoc, collection, doc, setDoc, Timestamp} from "firebase/firestore";
import {db} from "../../../firebase";

// Stylesheets
import './quizTables.css';
import './quizTablesMobile.css';

// Components and Objects
import FloorPlan from './floorPlan/FloorPlan';
import {
  TableObj,
  Booking,
  bookingConverter, quizConverter, Quiz
} from "../../../objects";

// Functions
import {getNext10WednesdaysWithSoldOut, isiOS} from "../../../func";

// Printing
import PrintPage from "./printPage/PrintPage";
import {useReactToPrint} from "react-to-print";
import {assignTables} from "../../updateBooking/betterTableFuncs";
import {getCurrentBookings, getLayout, saveAndUploadTableLayout} from "../../updateBooking/firebaseFuncs";
import {tables} from "../../updateBooking/initTables";
import {testTeams5} from "./testData";

const QuizTables = (props) => {
  let navigate = useNavigate()

  // Printing
  const [printBookings, setPrintBookings] = useState([]);
  const [teamToPrint, setTeamToPrint] = useState(null)
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (isiOS()) {
        setTimeout(() => {
          setTeamToPrint(null)
        }, 2500);
      } else {
        setTeamToPrint(null);
      }
    }
  });

  // Auth
  let {user, setUser} = props;
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  // Manual Booking
  const [name, setName] = useState("");
  const [people, setPeople] = useState(0);
  const [time, setTime] = useState("");

  // Date selector
  const [wednesdays, setWednesdays] = useState([]);
  const [date, setDate] = useState("");

  // Tables
  const [modifiedTables, setModifiedTables] = useState(tables)
  const [assignedTables, setAssignedTables] = useState(tables);

  // Booking Info and Layout
  const [bookings, setBookings] = useState([]);
  const [cannotBeSeated, setCannotBeSeated] = useState([])
  const [emptyTables, setEmptyTables] = useState([])
  const [totalPeople, setTotalPeople] = useState(0);
  const [foldingChairs, setFoldingChairs] = useState(0);
  const [emptySpaces, setEmptySpaces] = useState(0)
  const [dbLayout, setDbLayout] = useState(null);
  const [soldOutUpload, setSoldOutUpload] = useState(false);
  const [removeUpload, setRemoveUpload] = useState(false);

  useEffect(() => {
    if (wednesdays.length > 0) {
      for (let wednesday of wednesdays) {
        if (!wednesday.includes("[REMOVED]")) {
          setDate(wednesday.split(" [SOLD OUT]")[0]);
          break
        }
      }
    }
  }, [wednesdays]);

  useEffect(() => {
    if (wednesdays.length === 0) {
      getNext10WednesdaysWithSoldOut(false).then(newWeds => {
        setWednesdays(newWeds);
      })
    }
  }, [wednesdays]);

  function resetInfo() {
    setEmptyTables([])
    setCannotBeSeated([])
    setEmptySpaces(63)
    setTotalPeople(0)
  }

  useEffect(() => {
    if (date !== "") {
      setAssignedTables(tables)
      resetInfo()

      // setBookings(testTeams5)
      // calculateAssignments(bookings)

      getCurrentBookings(date).then((bookings) => {
        setBookings(bookings);
        calculateAssignments(bookings)

        getLayout(date).then((layout) => {
          if (layout !== null) {
            setAssignedTables(layout.assignedTables)
          }
        })
      })
    }
  }, [date]);

  useEffect(() => {
    calculateAssignments(bookings)
  }, [modifiedTables])

  function calculateAssignments(bookings) {
    if (bookings && bookings.length > 0) {
      setPrintBookings([])
      const {
        assignedTables,
        printBookings,
        cannotBeSeated,
        emptySpaces,
        noTables,
        foldingChairs,
        total
      } = assignTables(tables, modifiedTables, bookings);
      setAssignedTables(assignedTables)
      setPrintBookings(printBookings);
      setCannotBeSeated(cannotBeSeated)
      setEmptySpaces(emptySpaces)
      setEmptyTables(noTables)
      setFoldingChairs(foldingChairs)
      setTotalPeople(total);
    }
  }

  const sendBooking = async () => {
    let expireDate = new Date(Date.parse(date));
    expireDate.setDate(expireDate.getDate() + 14);
    let booking = new Booking(name, "Manual Booking", [people], date, time, new Date(), Timestamp.fromDate(expireDate));
    const ref = collection(db, "bookings").withConverter(bookingConverter)
    await addDoc(ref, booking).then(async (docRef) => {
      alert("Added booking");
      getCurrentBookings(date).then((bookings) => {
        setBookings(bookings);
      })
    })
  }

  useEffect(() => {
    if (teamToPrint !== null) {
      handlePrint()
    }
  }, [teamToPrint]);

  const uploadQuizInfoToFirestore = async (quiz) => {
    const ref = doc(db, "quiz", quiz.date).withConverter(quizConverter)
    await setDoc(ref, quiz).then(() => {

    })
  }

  useEffect(() => {
    if (date !== "" && date !== undefined && soldOutUpload.upload) {
      let removed = date.includes(" [REMOVED]");
      let quiz = new Quiz(date.replace(" [SOLD OUT]", "").replace(" [REMOVED]", ""), soldOutUpload.soldOut, removed)
      uploadQuizInfoToFirestore(quiz).then(r => window.location.reload());
    } else if (soldOutUpload['upload']) {
      setSoldOutUpload({upload: false, soldOut: false})
    }
  }, [soldOutUpload, date]);

  useEffect(() => {
    if (date !== "" && date !== undefined && removeUpload.upload) {
      let isSoldOut = date.includes(" [SOLD OUT]");
      let quiz = new Quiz(date.replace(" [SOLD OUT]", "").replace(" [REMOVED]", ""), isSoldOut, removeUpload.remove)
      uploadQuizInfoToFirestore(quiz).then(r => window.location.reload());
    } else if (removeUpload['upload']) {
      setRemoveUpload({upload: false, remove: false})
    }
  }, [removeUpload, date]);

  return (
    <>
      {user !== null ?
        <div className={'quizTablesOuterContainer'}>
          {teamToPrint == null || !isiOS() ? <>
            <h1>QUIZ LAYOUT</h1>
            <select id={'wednesdays'} onChange={(event) => {
              setDate(event.target.value.split(" [SOLD OUT]")[0].split(" [REMOVED]")[0]);
            }} value={date}>
              {wednesdays.map((date, index) => (
                <option key={index} value={date.split(" [REMOVED]")[0].split(" [SOLD OUT]")[0]}>
                  {date}
                </option>
              ))}
            </select>
            <div className="updateQuizInnerContainer">
              <button className={'adminBtn'} onClick={() => {
                setSoldOutUpload({upload: true, soldOut: true})
              }}>SUBMIT SOLD OUT
              </button>
              <button className={'adminBtn'} style={{backgroundColor: 'red'}} onClick={() => {
                setSoldOutUpload({upload: true, soldOut: false})
              }}>UN-SUBMIT SOLD OUT
              </button>
            </div>
            <br/>
            <div className="updateQuizInnerContainer">
              <button className={'adminBtn'} onClick={() => {
                setRemoveUpload({upload: true, remove: true})
              }}>REMOVE QUIZ
              </button>
              <button className={'adminBtn'} style={{backgroundColor: 'red'}} onClick={() => {
                setRemoveUpload({upload: true, remove: false})
              }}>UN-REMOVE QUIZ
              </button>
            </div>
            <div className={'quizTablesContainer'}>
              <FloorPlan tables={assignedTables} setTables={setAssignedTables}/>
              <div className={'quizTablesInfoContainer'}>
                <h2>Bookings:</h2>
                <ul>
                  {bookings.map((booking, index) => (
                    <li key={`${booking.bookingId}-${booking.name}-${index}`}>
                      {booking.name} (Size: {booking.totalSize}{Array.isArray(booking.size) ? ` [${booking.size.join('/')}]` : ``})
                      ({booking.time})
                      <button onClick={() => {
                        navigate(`/booking/${booking.bookingId}/${booking.email}`)
                      }}>🔍</button>
                      <button onClick={() => {
                        setTeamToPrint(booking)
                      }}>🖨️
                      </button>
                    </li>
                  ))}
                  <button style={{marginTop: '10px'}} className={'adminBtn'} onClick={() => {
                    setTeamToPrint(printBookings)
                  }}>PRINT ALL LABELS (USE CHROME)
                  </button>
                </ul>

                <h2>Table Assignments:</h2>
                <ul>
                  {/* Sort assignments by table ID before rendering */}
                  {assignedTables.sort((a, b) => {
                    const tableAId = a.id;
                    const tableBId = b.id;
                    return tableAId - tableBId;
                  }).map((table, index) => (
                    <li key={table.id}>
                      <span>Table {table.id} ({table.capacity}) has teams {table.teams.map((team, index) => (`${team.name} (${team.size}) `))}</span>
                      {table.id === 10 || table.id === 11 || table.id === 12 || table.id === 13 ? <span><button
                        className={'swapTeamBtn'} style={{fontSize: '12px'}} onClick={() => {
                        let newTable = table
                        if (newTable.capacity === 2) {
                          if (table.id !== 13) {
                            newTable.capacity = 4;
                          } else {
                            newTable.capacity = 5;
                          }
                        } else {
                          newTable.capacity = 2;
                        }
                        newTable.teams = [];
                        newTable.currentSize = 0;


                        const updatedArray = modifiedTables.map((item, i) => {
                          if (i === index) {
                            return newTable;
                          } else {
                            return item;
                          }
                        });

                        setModifiedTables(updatedArray);
                      }}>{table.capacity === 2 ? `⬆️` : `⬇️`}</button></span> : <></>}
                    </li>
                  ))}
                  <button className={'swapTeamBtn'} style={{fontSize: '12px'}} onClick={() => {
                    if (modifiedTables.length === 13) {
                      setModifiedTables([...modifiedTables, new TableObj(14, 6)])
                    } else {
                      setModifiedTables(modifiedTables.filter(table => table.id !== 14));
                    }
                  }}>{modifiedTables.length === 13 ? `➕` : `➖`}</button>
                </ul>

                <h2>{cannotBeSeated.length > 0 ? `Cannot Be Seated:` : <></>}</h2>
                <ul>
                  {cannotBeSeated.map((team, index) => {
                    return (
                      <li key={team.name}>
                        {team.name} (Size: {team.totalSize}{Array.isArray(team.size) ? ` [${team.size.join('/')}]` : ``})
                      </li>
                    )
                  })}
                </ul>

                <h2>{emptyTables.length > 0 ? `Empty Tables:` : <></>}</h2>
                <ul>
                  {emptyTables.sort((a, b) => {
                    const tableAId = a.id;
                    const tableBId = b.id;
                    return tableAId - tableBId;
                  }).map((table, index) => {
                    return (
                      <li key={table.id}>
                        ID: {table.id} (Size: {table.capacity})
                      </li>
                    )
                  })}
                </ul>

                <h2>{emptySpaces > 0 ? `Empty Spaces: ${emptySpaces}` : <></>}</h2>
                <h2>Folding Chairs in Use: {foldingChairs}</h2>
                <h2>Total People: {totalPeople}</h2>
              </div>
            </div>
            <div className="layoutBtnContainer">
              <button className={'adminBtn'} onClick={() => {
                calculateAssignments(bookings)
              }}>CALCULATE TABLE ASSIGNMENTS
              </button>
              <button className={'adminBtn'} onClick={() => {
                let currentEmptyTables = assignedTables.filter(table => table.teams.length === 0);
                saveAndUploadTableLayout(date, assignedTables, currentEmptyTables, emptySpaces, foldingChairs, totalPeople)
              }}>SAVE LAYOUT
              </button>
            </div>

            <br/>
            <hr/>
            <h2>Add manual booking</h2>
            <div className={'manualBookingContainer'}>
              <input onChange={(event) => {
                setName(event.target.value);
              }} placeholder={"Name for booking"} value={name} type={'fname'}></input>
              <select className={'quizSelect'} id={'time'} onChange={(event) => {
                setPeople(parseInt(event.target.value))
              }} value={people}>
                <option value={0}>Team size</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
                <option value={16}>16</option>
              </select>
              <select id={'time'} onChange={(event) => {
                setTime(event.target.value);
              }} value={time}>
                <option value={""}>Table time</option>
                <option value={"6:45pm"}>6:45pm</option>
                <option value={"7:00pm"}>7:00pm</option>
                <option value={"7:15pm"}>7:15pm</option>
                <option value={"7:30pm"}>7:30pm</option>
                <option value={"7:45pm"}>7:45pm</option>
              </select>
              <button className={'adminBtn'} onClick={() => {
                sendBooking()
              }}>SEND BOOKING
              </button>
              <br/><br/>
            </div>
          </> : <></>}
        </div>
        : <></>}
      {!Array.isArray(teamToPrint) && teamToPrint !== null ?
        <PrintPage ref={componentRef} team={teamToPrint}/> : <></>}
      {Array.isArray(teamToPrint) && teamToPrint !== null ? <div ref={componentRef}>
        {teamToPrint.map((team) => {
          return (
            <PrintPage team={team}/>
          )
        })}
      </div> : <></>}
    </>
  );
};

export default QuizTables;