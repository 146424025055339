import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";
import {quizConverter} from "../objects";

export const getNext10WednesdaysWithSoldOut = async (hideRemoved = true) => {
  const wednesdays = [];
  let quizData = [];
  let excludedDates = [];
  const querySnapshot = await getDocs(collection(db, "quiz"));
  querySnapshot.forEach((doc) => {
    let data = quizConverter.fromFirestore(doc)
    quizData.push(data);
    if (data.removed) {
      excludedDates.push(data.date)
    }
  });

  const today = new Date(Date.now() - 86400000);
  let day = today.getDay();
  let diff = (3 + 7 - day) % 7; // 3 represents Wednesday (0 is Sunday)

  if (diff === 0) {
    diff = 7; // If today is Wednesday, start from next Wednesday
  }

  let nextWednesday = new Date(today);
  nextWednesday.setDate(today.getDate() + diff);

  let total = 10;
  for (let i = 0; i < total; i++) {
    let dateString = new Date(nextWednesday).toDateString()
    if (!excludedDates.includes(dateString)) {
      wednesdays.push(dateString);
    } else {
      if (!hideRemoved) {
        wednesdays.push(dateString);
      }
      i -= 1;
    }
    nextWednesday.setDate(nextWednesday.getDate() + 7);
  }

  if (quizData.length > 0 && wednesdays.length > 0) {
    for (let i = 0; i < wednesdays.length; i++) {
      for (let j = 0; j < quizData.length; j++) {
        if (quizData[j].date === wednesdays[i] && quizData[j].soldOut) {
          wednesdays[i] = wednesdays[i] + " [SOLD OUT]"
        }
        if (quizData[j].date === wednesdays[i] && quizData[j].removed) {
          wednesdays[i] = wednesdays[i] + " [REMOVED]"
        }
      }
    }
    return wednesdays;
  }
}