import './tap.css';
import './tapMobile.css';
import './tapTablet.css';

import {Frame1, Frame2, Frame4, Frame3} from "../../../imgs";
import {useLayoutEffect, useState, useRef} from "react";

const Tap = (props) => {
  const { tapsOn, setTapsOn, beers, index, edge } = props;
  const [currentFrame, setCurrentFrame] = useState(0);
  let frames = [Frame1, Frame2, Frame3, Frame4];
  const rafRef = useRef(null);
  const lastUpdateTimeRef = useRef(0);
  const updateIntervalRef = useRef(50); // 50ms between frame updates

  const pourBeer = (index) => {
    let newTapsOn = tapsOn.map((tap, i) => i === index ? !tap : tap);
    setTapsOn(newTapsOn);
  }

  const updateFrame = (timestamp) => {
    if (timestamp - lastUpdateTimeRef.current >= updateIntervalRef.current) {
      setCurrentFrame(prev => {
        if (tapsOn[index]) {
          return prev < 3 ? prev + 1 : prev;
        } else {
          return prev > 0 ? prev - 1 : prev;
        }
      });
      lastUpdateTimeRef.current = timestamp;
    }

    rafRef.current = requestAnimationFrame(updateFrame);
  }

  useLayoutEffect(() => {
    rafRef.current = requestAnimationFrame(updateFrame);
    return () => cancelAnimationFrame(rafRef.current);
  }, [tapsOn, index]);

  return (
    <div className={"grid-item tap-and-liquid"}>
      <img key={`tapImg${currentFrame}`} onClick={() => {
        pourBeer(index)
      }} className={edge ? 'tap tap-edge' : 'tap'} src={frames[currentFrame]} alt={`Beer tap for ${beers[index].name}`}/>
    </div>
  )
}

export default Tap;