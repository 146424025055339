import './gallery.css';
import './galleryMobile.css';
import './galleryTablet.css';

import BeholdWidget from "@behold/react";

const Gallery = () => {
  return (
    <div className={'galleryContainer'}>
      <h1 style={{marginTop: '60px'}}>GALLERY</h1>
      <BeholdWidget feedId="jY9QnoHbBn32NWBWh6EU"/>
    </div>
  )
}

export default Gallery