// Stylesheets
import './updateEvents.css';
import './updateEventsMobile.css';
import './updateEventsTablet.css';

// React
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// Firebase
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {collection, deleteDoc, doc, getDocs, setDoc} from "firebase/firestore";
import {db} from "../../../firebase";

// Objects
import {Poster, posterConverter} from "../../../objects/Poster";
import Compressor from "compressorjs";

const UpdateEvents = (props) => {
  let navigate = useNavigate();
  let user = props.user;
  let setUser = props.setUser;
  let auth = getAuth();

  let [newPosterObjs, setNewPosterObjs] = useState([(new Poster('', '', '', ''))]);

  const storage = getStorage();
  let [startUpload, setStartUpload] = useState(false);

  const getCurrentEvents = async () => {
    let objs = []
    const querySnapshot = await getDocs(collection(db, "posters"));
    querySnapshot.forEach((doc) => {
      let poster = posterConverter.fromFirestore(doc);
      objs.push(poster);
    });
    if (objs.length > 0) {
      setNewPosterObjs(objs)
    }
  }

  useEffect(() => {
    getCurrentEvents()
  }, []);

  useEffect(() => {
    if (newPosterObjs.length > 0 && newPosterObjs[newPosterObjs.length - 1].imageUrl !== '') {
      setNewPosterObjs(newPosterObjs => [...newPosterObjs, (new Poster('', '', '', ''))]);
    }
  }, [newPosterObjs]);

  const uploadToStorage = async () => {
    for (let i in newPosterObjs) {
      let posterObj = newPosterObjs[i]
      if (posterObj.imageUrl === '') {
        continue
      }

      if (posterObj.imageUrl instanceof File) {
        let storageRef = ref(storage, `Posters/${posterObj.imageUrl.name}`);
        const uploadTask = uploadBytesResumable(storageRef, posterObj.imageUrl);

        uploadTask.on('state_changed',
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              console.log('File available at', downloadURL);
              posterObj.name = posterObj.imageUrl.name
              posterObj.imageUrl = downloadURL
              await uploadToFirestore(posterObj)
            });
          });
      } else {
        await uploadToFirestore(posterObj)
      }
    }
    alert("Successfully updated posters")
    setStartUpload(false)
  }

  const uploadToFirestore = async (posterObj) => {
    console.log(posterObj)
    const ref = doc(db, "posters", posterObj.name).withConverter(posterConverter)
    await setDoc(ref, posterObj).then(() => {
      console.log("firebase ", posterObj.name)
    })
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  // Remove all beers before adding
  const deleteCurrentDB = async () => {
    let ids = []
    const querySnapshot = await getDocs(collection(db, "posters"));
    querySnapshot.forEach((doc) => {
      ids.push(doc.id)
    });

    for (let i = 0; i < ids.length; i++) {
      await deleteDoc(doc(db, "posters", ids[i]));
    }
  }

  useEffect(() => {
    if (startUpload) {
      deleteCurrentDB().then(r => uploadToStorage())
    }
  }, [startUpload]);

  return (
    <>
      {user === null ? <div></div> : <div className={'outerUpdateEventsContainer'}>
        <h1 className={'adminHeader'}>UPDATE EVENTS</h1>
        Click on a poster to remove it, set an expiry for the event and it will automatically be removed from the home
        page.
        <div>
          <div className={'eventsContainer updateEventsContainer'}>
            {newPosterObjs.map((poster, index) => (
              <div key={index} className="updatePosterContainer">
                <img onClick={() => {
                  // Remove selected poster
                  let newPosters = []
                  for (let i = 0; i < newPosterObjs.length; i++) {
                    if (i !== index) {
                      newPosters.push(newPosterObjs[i])
                    }
                  }
                  setNewPosterObjs(newPosters)
                }} src={newPosterObjs.length > index && newPosterObjs[index].imageUrl instanceof File ?
                  URL.createObjectURL(newPosterObjs[index].imageUrl) : newPosterObjs[index].imageUrl}/>
                <div>
                  <div>
                    <input
                      type="file"
                      name="myImage"
                      onChange={(event) => {
                        let file = event.target.files[0];

                        new Compressor(file, {
                          retainExif: true,
                          quality: 0.45,
                          convertSize: 1000000,

                          success(result) {
                            let compressedFile = null;
                            if (result instanceof File) compressedFile = result;
                            else {
                              compressedFile = new File([result], result.name, {
                                type: result.type,
                              });
                            }

                            if (newPosterObjs[0].imageUrl === '') { // If currently empty
                              let tempPoster = newPosterObjs[0];
                              tempPoster.imageUrl = compressedFile
                              setNewPosterObjs([tempPoster])
                            } else {
                              let tempPosters = []
                              for (let i = 0; i < newPosterObjs.length; i++) {
                                if (index === i) {
                                  let tempPoster = newPosterObjs[i];
                                  tempPoster.imageUrl = compressedFile
                                  tempPosters.push(tempPoster)
                                } else {
                                  tempPosters.push(newPosterObjs[i])
                                }
                              }
                              console.log(tempPosters)
                              setNewPosterObjs(tempPosters);
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  <div>URL:</div>
                  <input className={'adminInput'} onChange={event => {
                    let newPosters = []
                    for (let i = 0; i < newPosterObjs.length; i++) {
                      if (i === index) {
                        let newPoster = newPosterObjs[i]
                        newPoster.eventUrl = event.target.value
                        newPosters.push(newPoster)
                      } else {
                        newPosters.push(newPosterObjs[i])
                      }
                    }
                    setNewPosterObjs(newPosters)
                  }} value={newPosterObjs[index].eventUrl}/>
                  <div>EXPIRY:</div>
                  <input className={'adminInput'} onChange={event => {
                    let newPosters = []
                    for (let i = 0; i < newPosterObjs.length; i++) {
                      if (i === index) {
                        let newPoster = newPosterObjs[i]
                        newPoster.expiry = event.target.value
                        newPosters.push(newPoster)
                      } else {
                        newPosters.push(newPosterObjs[i])
                      }
                    }
                    setNewPosterObjs(newPosters)
                  }} type="date" id="expiry" name="expiry" required pattern="\d{4}-\d{2}-\d{2}"
                         value={newPosterObjs[index].expiry}/>
                  <div>
                    <input type="checkbox" id="infiniteCheck" name="infiniteCheck" onChange={() => {
                      let newPosters = []
                      for (let i = 0; i < newPosterObjs.length; i++) {
                        if (i === index) {
                          let newPoster = newPosterObjs[i]
                          if (newPoster.expiry === "2035-01-01") {
                            newPoster.expiry = new Date();
                          } else {
                            newPoster.expiry = '2035-01-01';
                          }
                          newPosters.push(newPoster)
                        } else {
                          newPosters.push(newPosterObjs[i])
                        }
                      }
                      setNewPosterObjs(newPosters)
                    }} checked={newPosterObjs[index].expiry === "2035-01-01"}/>
                    <label htmlFor="infiniteCheck">Infinite?</label>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <button className={'adminBtn'} onClick={() => {
              setStartUpload(true)
            }}>SAVE
            </button>
          </div>
        </div>
      </div>}
    </>
  )
}

export default UpdateEvents;