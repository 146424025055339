import {Team} from "../../../objects";

let testTeams = [
  new Team(1, "Alice", 2, "11 Sep 2024", "7:45pm"),
  new Team(2, "Bob", 2, "11 Sep 2024", "7:45pm"),
  new Team(3, "Carl", 4, "11 Sep 2024", "7:45pm"),
  new Team(4, "Dan", 6, "11 Sep 2024", "7:45pm"),
  new Team(5, "Ed", 3, "11 Sep 2024", "7:45pm"),
  new Team(6, "Fiona", 3, "11 Sep 2024", "7:45pm"),
  new Team(7, "Gary", 4, "11 Sep 2024", "7:45pm"),
  new Team(8, "Henry", 4, "11 Sep 2024", "7:45pm"),
  new Team(9, "Iona", 5, "11 Sep 2024", "7:45pm"),
  new Team(10, "James", 6, "11 Sep 2024", "7:45pm"),
  new Team(11, "Kat", 6, "11 Sep 2024", "7:45pm"),
  new Team(12, "Laura", 4, "11 Sep 2024", "7:45pm"),
  new Team(13, "Max", 4, "11 Sep 2024", "7:45pm")
]

const testTeams2 = [
  new Team("Alice", 4, "11 Sep 2024", "7:45pm"),
  new Team("Bob", 4, "11 Sep 2024", "7:45pm"),
  new Team("Carl", 4, "11 Sep 2024", "7:45pm"),
  new Team("Dan", 4, "11 Sep 2024", "7:45pm"),
  new Team("Ed", 6, "11 Sep 2024", "7:45pm"),
  new Team("Fiona", 6, "11 Sep 2024", "7:45pm"),
  new Team("Gary", 4, "11 Sep 2024", "7:45pm"),
  new Team("Henry", 4, "11 Sep 2024", "7:45pm"),
  new Team("Iona", 5, "11 Sep 2024", "7:45pm"),
  new Team("James", 4, "11 Sep 2024", "7:45pm"),
  new Team("Kat", 4, "11 Sep 2024", "7:45pm"),
  new Team("Laura", 4, "11 Sep 2024", "7:45pm"),
  new Team("Max", 4, "11 Sep 2024", "7:45pm"),
  new Team("Nigel", 3, "11 Sep 2024", "7:45pm"),
  new Team("Ollie", 3, "11 Sep 2024", "7:45pm")
];

const thisWeekTeams = [
  new Team(1, "Rob & Ahmed", 5, "11 Sep 2024", "7:45pm"),
  new Team(2, "Joe & Pip", 8, "11 Sep 2024", "7:45pm"),
  new Team(3, "Toms", 5, "11 Sep 2024", "7:45pm"),
  new Team(4, "Oliver", 2, "11 Sep 2024", "7:45pm"),
  new Team(5, "Lewis", 6, "11 Sep 2024", "7:45pm"),
  new Team(6, "Jack", 6, "11 Sep 2024", "7:45pm"),
  new Team(7, "Ollie", 2, "11 Sep 2024", "7:45pm"),
  new Team(8, "Harry", 6, "11 Sep 2024", "7:45pm"),
  new Team(9, "Holly", 6, "11 Sep 2024", "7:45pm"),
  new Team(10, "Cameron", 3, "11 Sep 2024", "7:45pm"),
  new Team(11, "Sam", 4, "11 Sep 2024", "7:45pm")
];

const thisWeekTeamsNoPerm = [
  new Team("Oliver", 2, "11 Sep 2024", "7:45pm"),
  new Team("Lewis", 6, "11 Sep 2024", "7:45pm"),
  new Team("Jack", 6, "11 Sep 2024", "7:45pm"),
  new Team("Ollie", 2, "11 Sep 2024", "7:45pm"),
  new Team("Harry", 6, "11 Sep 2024", "7:45pm"),
  new Team("Holly", 6, "11 Sep 2024", "7:45pm"),
  new Team("Cameron", 3, "11 Sep 2024", "7:45pm"),
  new Team("Sam", 4, "11 Sep 2024", "7:45pm")
];

let testTeams4 = [
  new Team(1, "Alpha", 3, "12 Sep 2024", "6:30pm"),
  new Team(2, "Beta", 5, "12 Sep 2024", "6:30pm"),
  new Team(3, "Gamma", 2, "12 Sep 2024", "6:30pm"),
  new Team(4, "Delta", 6, "12 Sep 2024", "6:30pm"),
  new Team(5, "Epsilon", 4, "12 Sep 2024", "6:30pm"),
  new Team(6, "Zeta", 2, "12 Sep 2024", "6:30pm"),
  new Team(7, "Eta", 2, "12 Sep 2024", "6:30pm"),
  new Team(8, "Theta", 2, "12 Sep 2024", "6:30pm"),
  new Team(9, "Iota", 3, "12 Sep 2024", "6:30pm"),
  new Team(10, "Kappa", 2, "12 Sep 2024", "6:30pm"),
  new Team(11, "Lambda", 3, "12 Sep 2024", "6:30pm"),
  new Team(12, "Mu", 4, "12 Sep 2024", "6:30pm"),
  new Team(13, "Nu", 2, "12 Sep 2024", "6:30pm"),
  new Team(14, "Xi", 3, "12 Sep 2024", "6:30pm"),
  new Team(15, "Omicron", 4, "12 Sep 2024", "6:30pm"),
  new Team(16, "Pi", 4, "12 Sep 2024", "6:30pm"),
  new Team(17, "Rho", 6, "12 Sep 2024", "6:30pm")
];

let testTeams5 = [
  // new Team("a", 1, "Alpha", [6, 6], 12, "12 Sep 2024", "6:30pm", ""),
  new Team("a", 1, "Alpha", 5, 5, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 2, "Beta", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 3, "Gamma", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 4, "Delta", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 5, "Epsilon", [6,3], 9, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 6, "Eta", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 7, "Zeta", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 8, "Theta", 6, 6, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 9, "Iota", 4, 4, "12 Sep 2024", "6:30pm", ""),
  new Team("B", 10, "Kappa", 2, 2, "12 Sep 2024", "6:30pm", ""),
  // new Team("B", 11, "Lambda", 4, 4, "12 Sep 2024", "6:30pm", ""),
  // new Team("B", 8, "Kappa", [3, 6], 9, "12 Sep 2024", "6:30pm", ""),
  // new Team("B", 9, "Lambda", 3, 3, "12 Sep 2024", "6:30pm", ""),
  // new Team("B", 67, "Mu", [6, 6], 12, "12 Sep 2024", "6:30pm", ""),
];

export {testTeams, testTeams4, thisWeekTeamsNoPerm, testTeams2, testTeams5, thisWeekTeams}