// React
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

// Images
import {
  Doodles, DoodlesMobile,
  Facebook,
  Instagram,
  TikTok,
  TwitterX
} from "../../imgs";

// Stylesheets
import './home.css';
import './homeMobile.css';
import './homeTablet.css';

// Components
import {BeerCard, CraftBeer, Events, Header, HeroImages, QuizDialog, Reviews, TapWall, VisitUs} from "../../components";
import {useIsVisible, useWindowDimensions, setRndRotations} from "../../func";

// Objects
import {Beer} from "../../objects";

// Modules
import BeholdWidget from "@behold/react";

const Home = (props) => {
  let {quizOpen, setQuizOpen, showQuiz} = props;

  let navigate = useNavigate();

  let { height, width } = useWindowDimensions();
  let [rotations, setRotations] = useState(Array(6).fill(0))
  const tapRef = useRef(null)
  const isTapWallVisible = useIsVisible(tapRef);
  const landingRef = useRef(null)
  const isLandingVisible = useIsVisible(landingRef);
  const [show, setShow] = useState(false)

  const [beerCardOpen, setBeerCardOpen] = useState(false)
  const [beerCard, setBeerCard] = useState(new Beer('', '', '', '', '', '', '', '', '', '', ''))

  const [eventsTitle, setEventsTitle] = useState("EVENTS")

  const [scrollYPosition, setScrollYPosition] = useState(0);

  const handleScroll = () => {
    const newScrollYPosition = window.pageYOffset;
    setScrollYPosition(newScrollYPosition);
  };

  useEffect(() => {
    if (showQuiz) {
      setQuizOpen(true);
    }
  }, [showQuiz])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const executeScroll = () => {
    tapRef.current.scrollIntoView({behavior: 'smooth'})
  }

  // Scroll down after 1 second if still looking at landing image
  useEffect(() => {
    setTimeout(() => {
      if (scrollYPosition < tapRef.current.getBoundingClientRect().top && !isTapWallVisible) {
        executeScroll()
      }
    }, 1000)
  }, []);

  // Show header banner if past the landing image
  useEffect(() => {
    if (scrollYPosition > landingRef.current.getBoundingClientRect().bottom && !isLandingVisible) {
      setShow(true)
    } else if (isLandingVisible) {
      setShow(false)
    }
  }, [scrollYPosition]);

  useEffect(() => {
    if (beerCard.name !== '') {
      setBeerCardOpen(true)
    } else {
      setBeerCardOpen(false)
    }
  }, [beerCard]);

  return (
    <div className={'home'}>
      <QuizDialog width={width} open={quizOpen} setOpen={setQuizOpen}/>
      <BeerCard open={beerCardOpen} setOpen={setBeerCardOpen} beerCard={beerCard} setBeerCard={setBeerCard}/>
      <Header width={width} show={show} isLandingVisible={isLandingVisible} landingRef={landingRef}
              scrollYPosition={scrollYPosition}/>
      <div className={'landingContainer'}>
        <img ref={landingRef} className={'landingImg'} src={width > 640 ? Doodles : DoodlesMobile} onClick={() => {
          executeScroll()
        }} alt={'Mosaic tap logo with lots of small illustrations around the outside'}/>
      </div>
      <TapWall refProp={tapRef} beerCard={beerCard} setBeerCard={setBeerCard}/>
      <div className={'belowWall'}>
        <h1 className={'eventsTitle'} style={{userSelect: 'none', cursor: 'pointer'}} onClick={() => {
          setRotations(setRndRotations())
        }}>{eventsTitle}</h1>
        <Events rotations={rotations} setRotations={setRotations} quizOpen={quizOpen} setQuizOpen={setQuizOpen}
                setEventsTitle={setEventsTitle}/>
        {/*<Gallery />*/}
        <h1 style={{marginBottom: '15px', marginTop: '70px'}}>GALLERY</h1>
        <HeroImages/>
        <BeholdWidget feedId="jY9QnoHbBn32NWBWh6EU"/>
        <VisitUs width={width}/>
        <div className={'visitUsContainer'}>
          <iframe title={'mosaicMap'}
                  className={'googleMap'}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2290.388397353555!2d-1.622654122552407!3d54.966287672803126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e71011071a15d%3A0xdf550da4ecea6ed0!2sMosaic%20Tap!5e0!3m2!1sen!2suk!4v1714333804082!5m2!1sen!2suk"
                  width="800" height="600" style={{border: 0}} allowFullScreen="" loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"></iframe>
          <p className={'mapText'}>Mosaic Tap, Arch 7, Forth Goods Yard, NE1 3PG</p>
          <p className={'mapText'}><i>6 min walk from Central Station</i></p>
        </div>
        <Reviews/>
        <CraftBeer/>
        <div className={'socialsContainer'}>
          <img className={'socialImg'} src={Facebook} onClick={() => {
            window.open('https://www.facebook.com/mosaictap/', "_blank")
          }} alt={'Facebook logo'}/>
          <img className={'socialImg'} src={Instagram} onClick={() => {
            window.open('https://www.instagram.com/mosaictap/', "_blank")
          }} alt={'Instagram logo'}/>
          <img className={'socialImg'} src={TikTok} onClick={() => {
            window.open('https://www.tiktok.com/@mosaictaptok', "_blank")
          }} alt={'TikTok logo'}/>
          <img className={'socialImg'} src={TwitterX} onClick={() => {
            window.open('https://twitter.com/mosaic_tap', "_blank")
          }} alt={'X logo'}/>
        </div>
      </div>
      <div className={'contactUs'}>
        <b>Any questions?</b> Email us at <i><a href={"mailto:info@mosaictap.com"}>info@mosaictap.com</a></i>
      </div>

      <div className={'footer footerDocsContainer'}>
        <div className={'footer footerLink footerDocs leftText'} onClick={() => {
          navigate('/terms')
        }}>
          <span>Terms & Conditions</span>
        </div>
        <div className={'footer middleText'}>
          <span>© Mosaic Tap 2024 - {new Date().getFullYear()}</span>
        </div>
        <div className={'footer footerLink footerDocs rightText'} onClick={() => {
          navigate('/privacy')
        }}>
          <span>Privacy Policy</span>
        </div>
      </div>
      <div className={'footer'} style={{paddingTop: '0px', paddingBottom: width <= 640 ? '30px' : '50px'}}>
        This website was developed by <a href={'https://ofox.co.uk'}><i>Oliver Fox</i></a>.
      </div>
    </div>
  )
}

export default Home