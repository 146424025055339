import React, {useEffect, useState} from 'react';
import {
  ref,
  getDownloadURL,
  getStorage,
  listAll,
  deleteObject,
  uploadBytesResumable
} from 'firebase/storage';
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import Compressor from "compressorjs";
import './betterUpdateHeroImages.css';

function BetterUpdateHeroImages(props) {
  const navigate = useNavigate();

  // Props
  let {user, setUser} = props;

  // Firebase
  let auth = getAuth();
  const storage = getStorage();

  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [urls, setUrls] = useState([]);
  const [existingUrls, setExistingUrls] = useState([]);

  useEffect(() => {
    const fetchExistingImages = async () => {
      const imagesRef = ref(storage, 'Hero/');

      try {
        const response = await listAll(imagesRef);
        const urls = await Promise.all(
          response.items.map((item) => getDownloadURL(item))
        );
        setExistingUrls(urls);
      } catch (error) {
        console.error('Error fetching existing images:', error);
      }
    };

    fetchExistingImages();
  }, []);

  const handleChange = (e) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  const handleUpload = () => {
    images.forEach((image) => {
      new Compressor(image, {
        quality: 0.45, // 0.8 is the default
        maxWidth: 1000, // Max width and height
        maxHeight: 1000,
        convertSize: 1000000, // 1 MB

        success(result) {
          const storageRef = ref(storage, `Hero/${result.name}`);
          const uploadTask = uploadBytesResumable(storageRef, result);

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progress);

            },
            (error) => {
              console.error('Upload error:', error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setUrls((prevUrls) => [...prevUrls, downloadURL]);
                console.log('Uploaded image:', downloadURL);
              });
            }
          );
        },
        error(err) {
          console.log(err.message);
        },
      });
    });
  };

  const handleDelete = async (url) => {
    try {
      const imageName = url.split('Hero%2F')[1].split('?')[0]; // Changed to 'Hero%2F'
      const imageRef = ref(storage, `Hero/${imageName}`); // Changed to 'Hero/'
      await deleteObject(imageRef);

      // Update existingUrls state after successful deletion
      setExistingUrls((prevUrls) => prevUrls.filter((u) => u !== url));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      navigate('/admin')
    }
  });

  return (
    <>
      {user !== null ?
        <>
          <div className={'updateHeroContainer'}>
            <h1 className={'adminHeader'}>UPDATE HERO IMAGES</h1>
            <div>
              Select files, press upload and wait for them to appear below "Uploaded Images". To delete, just click on
              the
              image to remove it.
            </div>
            <div>
              <input type="file" multiple onChange={handleChange}/>
              <button onClick={handleUpload}>Upload</button>
              <progress value={progress} max="100"/>
            </div>

            <h2>Existing Images:</h2>
            <div className={'updateImagesContainer'}>
              {existingUrls.map((url, i) => (
                <img
                  key={i}
                  src={url}
                  alt="Existing"
                  style={{height: '200px', width: 'auto', cursor: 'pointer'}} // Fixed height, auto width
                  onClick={() => handleDelete(url)}
                />
              ))}
            </div>

            <h2>Uploaded Images:</h2>
            <div className={'updateImagesContainer'}>
              {urls.map((url, i) => (
                <img
                  key={i}
                  src={url}
                  alt="Uploaded"
                  style={{height: '200px', width: 'auto'}} // Fixed height, auto width
                />
              ))}
            </div>
          </div>
        </> : <></>
      }
    </>
  );
}

export default BetterUpdateHeroImages;