// Stylesheets
import './events.css'
import './eventsMobile.css'

// React
import {useEffect, useState} from "react";

// Functions
import {randomIntFromInterval} from "../../func";
import setRndRotations from "../../func/getRandomRotations";

// Firebase
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {db} from "../../firebase";

// Objects
import {posterConverter} from "../../objects";

// Imgs
import {PosterTexture1, PosterTexture2, PosterTexture3, PosterTexture4, PosterTexture5} from "../../imgs";

import useWindowDimensions from "../../func/getWindowDimensions";

const Events = (props) => {
  let {rotations, setRotations, quizOpen, setQuizOpen, setEventsTitle} = props

  const {height, width} = useWindowDimensions();

  let [posterHeight, setPosterHeight] = useState(`16vw`);
  let [posterWidth, setPosterWidth] = useState(`12vw`);

  let [posters, setPosters] = useState(Array(0).fill(null))
  let textures = [PosterTexture1, PosterTexture2, PosterTexture3, PosterTexture4, PosterTexture5];
  let [textureIndexes, setTextureIndexes] = useState([])

  const getCurrentEvents = async () => {
    let posterObjs = []
    const q = await query(collection(db, "posters"), orderBy("expiry"));
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      let poster = posterConverter.fromFirestore(doc);
      let date = Date.parse(poster.expiry)
      let yesterday = ((new Date()).getTime());
      yesterday -= 60 * 60 * 24 * 1000
      if (date >= yesterday) {
        if (poster.eventUrl === "quiz") {
          posterObjs.unshift(poster)
        } else {
          posterObjs.push(poster)
        }
      }
    });
    setPosters(posterObjs);
  }

  useEffect(() => {
    setRotations(rotations);
  }, [rotations]);

  useEffect(() => {
    setRotations(setRndRotations());
    getCurrentEvents();
  }, []);

  useEffect(() => {
    if (textureIndexes.length < 1) {
      let indexes = []
      for (let i = 0; i < posters.length; i++) {
        let rnd = randomIntFromInterval(0, 4);
        indexes.push(rnd);
      }
      setTextureIndexes(indexes)
    }
  }, [posters]);

  useEffect(() => {
    if (posters.length === 1 && posters[0].eventUrl === "quiz") {
      setEventsTitle("QUIZ")
    } else {
      setEventsTitle("EVENTS")
    }
  }, [posters]);

  useEffect(() => {
    if (posters.length === 1 && posters[0].eventUrl === "quiz") {
      if (width > 1400) {
        setPosterHeight('20vw')
        setPosterWidth('16vw')
      } else if (width <= 1400 && width > 640) {
        setPosterHeight('32vw')
        setPosterWidth('24vw')
      } else {
        setPosterHeight('74vw')
        setPosterWidth('58vw')
      }
    } else {
      if (width > 1400) {
        setPosterHeight('16vw')
        setPosterWidth('12vw')
      } else if (width <= 1400 && width > 640) {
        setPosterHeight('24vw')
        setPosterWidth('18vw')
      } else {
        setPosterHeight('37vw')
        setPosterWidth('29vw')
      }
    }

  }, [width]);

  return (
    <div
      className={'eventsContainer'}>
      {posters.map((poster, index) => (
        <div
          onClick={() => {
            if (poster.eventUrl === "quiz") {
              setQuizOpen(true)
            } else if (poster.eventUrl !== 'test' && poster.eventUrl !== '') {
              window.open(poster.eventUrl, "_blank")
            }
          }}
          key={index}
          className="posterContainer"
          style={{
            transform: `rotate(${rotations[index]}deg)`,
            height: posterHeight,
            width: posterWidth,
            margin: `0 5vw ${((width >= 640 && posters.length > 3) || (width < 640 && posters.length > 2)) &&
            ((width >= 640 && (((index + 1) - ((Math.ceil(posters.length / 3) - 1) * 3)) > 0)) || (width < 640 && (((index + 1) - ((Math.ceil(posters.length / 2) - 1) * 2)) > 0)))
              ? '0' : width < 640 ? '12vw' : '7vw'} 5vw`}}
        >
          <img className={'texture'} style={{backgroundImage: `url('${textures[textureIndexes[index]]}')`}}/>
          <img style={{backgroundImage: `url('${poster.imageUrl}')`}}/>
        </div>
      ))}
    </div>
  )
}

export default Events